<template>
  <div class="ztoh-sso-area">
    <el-row class="header">
      <el-col :md="12">
        <img data-v-d16d6306="" src="../assets/millennia-institute-landscape-putih-transparan.png" height="40" style="margin-left: 20px; margin-top: 5px;">
      </el-col>
      <el-col :md="12">
        <div style="margin-top: 7px; margin-right: 20px; color: #fff; display: flex; float: right;">
          <el-avatar size="medium" src="https://test-icicles.com/images/9-best-online-avatars-and-how-to-make-your-own-[2020]-4.png"></el-avatar>
          <span style="margin-top: 6px; margin-left: 10px">Asmuni Nitiprojo</span>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="5" class="left-sidebar">
        <!-- <el-row style="background-color: #6fa5d7; color: #fff; box-shadow: 1px 0 5px 0 #dfe4e6;">
          <el-row type="flex" align="middle" justify="center" style="font-size: 20px; font-weight: 600;padding: 20px !important">
            <img src="https://i.ibb.co/3YCkB4P/clipboard.png" width="30">
            <p style="padding-left: 6px">SSO</p>
          </el-row>
        </el-row> -->
        <el-row class="left-sidebar-area">
          <div>
            <el-tree
              icon-class="el-icon-reading"
              :data="data"
              :props="defaultProps"
              @node-click="handleNodeClick"
            >
            </el-tree>
          </div>
        </el-row>
      </el-col>
      <el-col :md="19">
        <el-row class="container-area">
          <div class="container-area-align">
            <el-card class="card-size">
              <el-row :gutter="55" style="margin-bottom: 55px">
                <el-col :md="8" style="text-align: center">
                  <div class="area-size">
                    <a target="_blank" href="http://lms.millennia-institute.id">
                      <img src="../assets/1.png" width="90" height="auto" />
                      <p style="margin-top: 10px">Courses</p></a
                    >
                  </div>
                </el-col>
                <el-col :md="8">
                  <div class="area-size">
                    <a target="_blank" href="https://tbox.ztoh.id/">
                      <img
                        src="../assets/3.png"
                        width="90"
                        height="auto"
                      />
                      <p style="margin-top: 10px">Conferences</p></a
                    >
                  </div>
                </el-col>
                <el-col :md="8">
                  <div class="area-size">
                    <a target="_blank" href="https://sbox.ztoh.id/">
                      <img src="../assets/4.png" width="90" height="auto" />
                      <p style="margin-top: 10px">Chat</p></a
                    >
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="55">
                <el-col :md="8">
                  <div class="area-size">
                    <a target="_blank" href="#">
                      <img
                        src="../assets/2.png"
                        width="90"
                        height="auto"
                      />
                      <p style="margin-top: 10px">Administration</p></a
                    >
                  </div>
                </el-col>
                <el-col :md="8">
                  <div class="area-size">
                    <a target="_blank" href="https://mizan.ztoh.id">
                      <img
                        src="../assets/5.png"
                        width="90"
                        height="auto"
                      />
                      <p style="margin-top: 10px">Mail</p></a
                    >
                  </div>
                </el-col>
                <el-col :md="8">
                  <div class="area-size">
                    <a target="_blank" href="http://student.millennia-institute.id/asmuni.html">
                      <img src="../assets/6.png" width="90" height="auto" />
                      <p style="margin-top: 10px">Profile</p></a
                    >
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        data: [{
          label: 'Transcript',
          children: [{
            label: 'Semester 1',
            children: [{
              label: 'GPA 4'
            }]
          },{
            label: 'Semester 2',
            children: [{
              label: 'GPA 3'
            }]
          },{
            label: 'Semester 3',
            children: [{
              label: 'GPA -'
            }]
          }]
        }, {
          label: 'Archive',
          children: [{
            label: 'Semester 1',
            children: [{
              label: 'Materi A',
                children: [{
                label: 'Self Study Design',
                },{
                label: 'Foundation of Education',
                },{
                label: 'English',
                },{
                label: 'Education in the World Throughout History',
                  },{
                label: 'Digital Literacy',
                  },{
                label: 'Communication and Collaboration',
                  }]
            }]
          },{
            label: 'Semester 2',
            children: [{
              label: 'Materi B',
              children: [{
                label: 'Why Become a Teacher',
                  },{
                label: 'Technology and Innovation in Education',
                  },{
                label: 'Positive Psychology in Education',
                  },{
                label: 'Multiple Intelligences',
                  },{
                label: 'Learning Theories',
                  },{
                label: 'Educational Leadership and Management',
                  },{
                label: 'Critical, Creative, and System Thinking',
                  }]
            }]
          },{
            label: 'Semester 3',
            children: [{
              label: 'Materi c',
              children: [{
                label: '-',
                  }]
            }]
          }]
        }],
        defaultProps: {
          children: 'children',
          label: 'label'
        }
      };
    },
    methods: {
      handleNodeClick(data) {
        console.log(data);
      }
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.ztoh-sso-area {
  font-size: 16px;
  color: #1c1c1c;

  .header {
    background: rgb(24, 144, 255)
  }

  p {
    padding: 0;
    margin: 0;
  }
  .el-tree {
    position: relative;
    cursor: default;
    background: none;;
    color: #4b4b4d;
  }
  .el-tree-node__label {
    font-size: 20px;
    font-weight: 550;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .el-tree-node__children {
    .el-tree-node__label {
      font-size: 14px !important;
    }
  }
  .el-tree-node__content {
    height: 50px;
  }
  .left-sidebar {
    border-right: solid 2px #eaeaea;
    height: 100vh;
    box-shadow: 1px 0 5px 0 #dfe4e6;

    .left-sidebar-area {
      padding: 20px;
      overflow: auto;
      height: calc(100vh - 70px);
    }
  }
  // .left-sidebar {
  //   bottom: 0;
  //   left: 0;
  //   position: fixed;
  //   top: 0;
  //   max-height: calc(100vh - 0px);
  //   overflow-y: auto;
  //   z-index: 190;
  //   -webkit-box-shadow: 1px 0 5px 0 #dfe4e6;
  //   box-shadow: 1px 0 5px 0 #dfe4e6;
  //   -webkit-transition-property: top;
  //   transition-property: top;
  //   -webkit-transition-timing-function: linear;
  //   transition-timing-function: linear;
  //   -webkit-transition-duration: 0.3s;
  //   transition-duration: 0.3s;
  // }
  .container-area {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;
    text-align: center;
    .container-area-align {
      position: absolute;
      width: 100%;
      top: calc(50vh - 270px);
      a {
        background-color: #fff;
        text-decoration: none;
        color: #1c1c1c;
        font-weight: 550;
        width: 180px;
        height: 180px;
        display: flex;
        border-radius: 4px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: box-shadow 1s cubic-bezier(0.55, 0, 0.1, 1) 0s;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
        padding: 0 5px;

        &:hover {
          transform: scale(1.1);
        }
      }
      .el-card {
        background-color: aliceblue;
      }
    }
  }
  .card-size {
    .el-card__body {
      padding: 60px 60px;
    }
  }
}
</style>
